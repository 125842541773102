
import React, { useState } from 'react';
import axios from 'axios';

const DisplayData = () => {
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dateError, setDateError] = useState('');

    // Function to get today's date in YYYY-MM-DD format
    const getFormattedDateForInput = (date) => {
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    // Function to format date in DD-MM-YYYY format without leading zero for day and month less than 10
    const formatDisplayDate = (date) => {
        let parts = date.split('-');
        let year = parts[0];
        let month = parseInt(parts[1], 10); // Remove leading zero
        let day = parseInt(parts[2], 10); // Remove leading zero

        return `${day}-${month}-${year}`;
    };

    // Initialize startDate for input and displayDate for showing to the user
    const [startDate, setStartDate] = useState(getFormattedDateForInput(new Date()));
    const [displayDate, setDisplayDate] = useState(formatDisplayDate(startDate));

    const getData = async () => {
        setLoading(true); // Start loading
        try {
            const formattedDateForAPI = displayDate.split('-').reverse().join('-'); // Convert DD-MM-YYYY to YYYY-MM-DD for API
            
            console.log('formattedDateForAPI', formattedDateForAPI,displayDate);
            const response = await axios.post("https://us-central1-appydesigne-24e6c.cloudfunctions.net/widgets/fetchDataInRange", {
    startDate: displayDate,
            });
            setUserData(response.data.userData);
        } catch (error) {
            console.error('Error fetching data', error);
            alert('Error fetching data'); // Display error message
        } finally {
            setLoading(false); // End loading
        }
    };

    const copyToClipboard = (data) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(data)
                .then(() => console.log('Copied to clipboard: ' + data))
                .catch(err => console.error('Failed to copy: ', err));
        } else {
            // Fallback method using execCommand
            const textarea = document.createElement('textarea');
            textarea.value = data;
            document.body.appendChild(textarea);
            textarea.select();
            try {
                const successful = document.execCommand('copy');
                const msg = successful ? 'successful' : 'unsuccessful';
                console.log('Fallback: Copying text command was ' + msg);
                console.log('Copied to clipboard: ' + data);
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }
            document.body.removeChild(textarea);
        }
    };
    



    const handleDateChange = (e) => {
        const selectedDate = new Date(e.target.value);
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0); // Reset time to the start of the day for accurate comparison
    
        if (selectedDate > currentDate) {
            alert('The selected date cannot be in the future.');
            return; // Stop further execution if the selected date is in the future
        } else {
            setDateError(''); // Clear error if the selected date is valid
        }
    
        setStartDate(e.target.value); // Update the startDate in YYYY-MM-DD format for input
        setDisplayDate(formatDisplayDate(e.target.value)); // Update displayDate in DD-MM-YYYY format
    };


    if (loading) {
        return <div>Loading...</div>; 
    }

    return (
        <div>
            <header style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                <div>
                    <input 
                        type="date"
                        value={startDate}
                        onChange={handleDateChange} 
                    />
                </div>
                <button style={{marginLeft: "20px", color: '#fff', background: "#5858f2", width: "133px"}} onClick={getData}>Get Data</button>
            </header>
            {userData.map((user, index) => (
                <div key={index} className="user-data">
                    <h2>User ID: {user.userId}</h2>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                        {user.aiImage.map((image, imgIndex) => (
                            <div 
                                key={imgIndex} 
                                style={{ margin: "10px", width: "400px", flex: "1 0 20%" }} 
                                className="image-container"
                            >
                                <h3>Image {imgIndex + 1}</h3>
                                <img 
                                    src={image.Imageoutput.url} 
                                    alt={`Image ${imgIndex + 1}`} 
                                    style={{ width: '400px', height: '400px', objectFit: 'cover' }} 
                                />
                                <p>Prompt: {image.prompt}</p>
                                <button style={{marginLeft: "20px", color: '#fff', background: "#5858f2"}} onClick={() => copyToClipboard(image.prompt)}>Copy Prompt</button>
                                <button style={{marginLeft: "20px", color: '#fff', background: "#5858f2"}} onClick={() => copyToClipboard(image.Imageoutput.url)}>Copy Image URL</button>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default DisplayData;
